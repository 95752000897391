import React from 'react'
import '../App.css'
import { useEffect, useState } from 'react';
import Delete from '../Images/delete-icon.svg'
import { useToastContext } from './Toast';

const VerifiedUsersTable = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [verifiedUsersLength, setVerifiedUsersLength] = useState(0);
  const [newUserData, setNewUserData] = useState({
    handle: '',
    npub: '',
  });
  const { sendWarning, sendSuccess } = useToastContext();

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });
        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['verified_users_list', {}],
          }),
        });
        if (response.ok) {
          const result = await response.json();
          if (result.status) {
            setData(result.verified_users);
            setVerifiedUsersLength(result.verified_users_length);
          } else {
            console.error('API request failed:', result.error);
          }
        } else {
          console.error('Failed to fetch data from the API');
        }
        setLoading(false);
      } catch (error) {
        console.error('Error fetching data:', error);
        setLoading(false);
      }
    };
    fetchData();
  }, []);

  const handleDelete = async (npub, userName) => {
    const confirmed = window.confirm(`Are you sure you want to delete the user '${userName}'?`);
    if (confirmed) {
      try {
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });
        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['verified_users_delete', { "name": userName }],
          }),
        });
        if (response.ok) {
          setData((prevData) => prevData.filter((user) => user.npub !== npub));
          sendSuccess('User deleted successfully');
        } else {
          console.error('Failed to delete user:', response.statusText);
          sendWarning('Failed to delete user');
        }
      } catch (error) {
        console.error('Error deleting user:', error);
        sendWarning('Error deleting user');
      }
    }
  };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setNewUserData({
      ...newUserData,
      [name]: value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (data.some((user) => user.name === newUserData.handle)) {
      sendWarning(`User '${newUserData.handle}' is already verified`);
      return;
    }
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });
      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['verified_users_add', {
            "name": newUserData.handle,
            "npub": newUserData.npub,
          }],
        }),
      });
      if (response.ok) {
        const newUser = {
          name: newUserData.handle,
          npub: newUserData.npub,
        };
        setData((prevData) => [...prevData, newUser]);
        setNewUserData({
          handle: '',
          npub: '',
        });
        sendSuccess('User added successfully');
      } else {
        console.error('Failed to add user:', response.statusText);
        sendWarning('Failed to add user');
      }
    } catch (error) {
      console.error('Error adding user:', error);
      sendWarning('Error adding user');
    }
  };

  if (loading) {
    return <div className="loader"><span></span></div>;
  }

  if (data.length === 0) {
    return <div>No data available</div>;
  }

  return (
    <div>
      <form onSubmit={handleSubmit} className="AddUserForm">
        <label className="label">
          Handle:
          <input
            type="text"
            name="handle"
            value={newUserData.handle}
            onChange={handleInputChange}
            className="AddUserHandle"
          />
        </label>
        <label className="label">
          Npub:
          <input
            type="text"
            name="npub"
            value={newUserData.npub}
            onChange={handleInputChange}
            className="AddUserNpub"
          />
        </label>
        <button type="submit" className="SubmitAddUser"></button>
      </form>
      <div className="verifiedUserCounts">
        Verified Users: {verifiedUsersLength}
      </div>
      <table className="Verified_Table">
        <thead>
          <tr className="table_header">
            <th>Handle</th>
            <th>Npub</th>
            <th>Delete</th>
          </tr>
        </thead>
        <tbody>
          {data.map((user, index) => (
            <tr className="table_row" key={index}>
              <td>
                <a
                    className="verifiedLink"
                    href={`https://primal.net/${user.name}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                {user.name}
                </a>
            </td>
            <td>
                <a
                    className="verifiedLink"
                    href={`https://primal.net/p/${user.npub}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                {user.npub}
                </a>
            </td>
              <td>
                <button
                  className="Delete"
                  onClick={() => handleDelete(user.npub, user.name)}
                >
                  <img src={Delete} alt="" />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};


const VerifiedUsers = () => {
    return (
        <div className="MainBody">
                <VerifiedUsersTable />
        </div>
    )
}
 export default VerifiedUsers