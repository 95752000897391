import React, { useState, useEffect } from 'react';
import '../App.css';

function NetworkData() {
    const [recentData, setRecentData] = useState([]);
    const [deltaData, setDeltaData] = useState([]);
    const [recentDates, setRecentDates] = useState([]);
    const [deltaDates, setDeltaDates] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            try {
                await new Promise((resolve) => setTimeout(resolve, 500));

                const e = await window.nostr.signEvent({
                    created_at: Math.floor(new Date().getTime() / 1000),
                    kind: 10000222,
                    tags: [],
                    content: 'i want in',
                });

                const response = await fetch('https://admin.primal.net/api2', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        event_from_user: e,
                        request: ['network', {},],
                    }),
                });

                const data = await response.json();
                if (data && !data.error) {
                    const recentData = data.recent.slice(1);
                    const deltaData = data._7day_delta.slice(1);

                    setRecentData(recentData);
                    setDeltaData(deltaData);

                    const formatDataWithCommas = (data) => {
                        return data.map((row) => {
                            return row.map((value, colIndex) => {
                                if (colIndex > 0) {
                                    return value.toLocaleString();
                                }
                                return value;
                            });
                        });
                    };

                    const formattedRecentData = formatDataWithCommas(recentData);
                    const formattedDeltaData = formatDataWithCommas(deltaData);

                    setRecentData(formattedRecentData);
                    setDeltaData(formattedDeltaData);

                    const formatTimestampToDate = (timestamp) => {
                        const date = new Date(timestamp * 1000);
                        return date.toISOString().split('T')[0];
                    };

                    const recentDates = recentData.map(row => formatTimestampToDate(row[0]));
                    const deltaDates = deltaData.map(row => formatTimestampToDate(row[0]));

                    setRecentDates(recentDates);
                    setDeltaDates(deltaDates);
                } else {
                    console.error('Error fetching API data:', data.error);
                }
                setLoading(false);
            } catch (error) {
                console.error('Error fetching API data:', error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);


    return (
        <div className="MainBody">
            {loading ? (
                <div className="loader"><span></span></div>
            ) : (
            <>
            <div>
                <h2 className="TableTitles">Recent Data</h2>
                <table class="network_table">
                    <thead>
                        <tr className="table_header">
                            <th>Date</th>
                            <th>Users</th>
                            <th>Pubkeys</th>
                            <th>Zaps</th>
                            <th>Sats Zapped</th>
                            <th>Public Notes</th>
                            <th>Reposts</th>
                            <th>Reactions</th>
                            <th>Any Events</th>
                        </tr>
                    </thead>
                    <tbody>
                        {recentData.map((row, index) => (
                            <tr className="table_row"
                                key={index}>
                                <td>{recentDates[index]}</td>
                                {row.slice(1).map((value, colIndex) => (
                                    <td key={colIndex}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            <div>
                <h2 className="TableTitles">7-Day Delta Data</h2>
                <table class="network_table">
                    <thead>
                        <tr className="table_header">
                            <th>Date</th>
                            <th>Users</th>
                            <th>Pubkeys</th>
                            <th>Zaps</th>
                            <th>Sats Zapped</th>
                            <th>Public Notes</th>
                            <th>Reposts</th>
                            <th>Reactions</th>
                            <th>Any Events</th>
                        </tr>
                    </thead>
                    <tbody>
                        {deltaData.map((row, index) => (
                            <tr className="table_row"
                                key={index}>
                                <td>{deltaDates[index]}</td>
                                {row.slice(1).map((value, colIndex) => (
                                    <td key={colIndex}>{value}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
            </>
            )}
        </div>
    );
}

export default NetworkData;

