import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const timestampToDateString = (timestamp) => {

    const tsInMilliseconds = timestamp * 1000;
    const date = new Date(tsInMilliseconds);
    return date.toLocaleDateString();
}

const StackedBarChart = () => {
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState({
    ios: [],
    web: [],
    android: [],
    chart_labels: [],
  });

  const [isFetchingDone, setIsFetchingDone] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsFetchingDone(false);
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['home', {}],
          }),
        });

        if (response.ok) {
          const data = await response.json();
          setChartData({
            ios: data.chart_values.ios,
            web: data.chart_values.web,
            android: data.chart_values.android,
            date: data.chart_labels,
          });
        } else {
          console.error('Failed to fetch data from the API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      } finally {
        setIsFetchingDone(true);
      }
    };

    setTimeout(() => {
        fetchData();
    }, 2000)
  }, []);

  useEffect(() => {
    if (isFetchingDone && chartRef.current) {
      const ctx = chartRef.current.getContext('2d');
      const timestamps = chartData.date.map(timestampToDateString);
      const iosValues = chartData.ios;
      const webValues = chartData.web;
      const androidValues = chartData.android;

      new Chart(ctx, {
        type: 'bar',
        data: {
          labels: timestamps,
          datasets: [
            {
              label: 'Web',
              data: webValues,
              backgroundColor: 'rgba(255, 99, 132, 0.6)',
            },
            {
              label: 'iOS',
              data: iosValues,
              backgroundColor: 'rgba(75, 192, 192, 0.6)',
            },
            {
              label: 'Android',
              data: androidValues,
              backgroundColor: 'rgba(255, 205, 86, 0.6)',
            },
          ],
        },
        options: {
          scales: {
            x: {
                stacked: true,
            },
            y: {
              	stacked: true,
            },
          },
          maintainAspectRatio: false,
        },
      });
    }
  }, [chartData, isFetchingDone]);

  return (
    <div>
      <canvas ref={chartRef} style={{height: '350px'}}/>
    </div>
  );
};

export default StackedBarChart;