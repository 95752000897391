import React from 'react';
import '../App.css';
import { useState, useEffect } from 'react';
import HomeChartData from './Graphs';
import ActivationsChart from './ActivationGraph';
import SubsChart from './SubsChart';

function formatNumberWithCommas(number) {
  return number ? number.toLocaleString() : "0";
}

function HomeData() {
    const [data, setData] = useState(null);
    const [primalAccountBalance, setPrimalAccountBalance] = useState(0);
    const [walletTotalsStrikeAccountBalance, setWalletTotalsStrikeAccountBalance] = useState(0);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const signEvent = await window.nostr.signEvent({
            created_at: Math.floor(new Date().getTime() / 1000),
            kind: 10000222,
            tags: [],
            content: 'i want in',
          });

          const fetchHomeData = async (event) => {
            const response = await fetch('https://admin.primal.net/api2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                event_from_user: event,
                request: ['home', {}],
              }),
            });
            return response.json();
          };

          const fetchWalletTotals = async (event) => {
            const response = await fetch('https://admin.primal.net/api2', {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({
                event_from_user: event,
                request: ['wallet_totals', { kyc_level: 2 , offset: 0, limit: 50 }],
              }),
            });
            return response.json();
          };

          const [homeData, walletTotals] = await Promise.all([
            fetchHomeData(signEvent),
            fetchWalletTotals(signEvent)
          ]);

          if (homeData && !homeData.error) {
            setData(homeData);
          } else {
            console.error('Error fetching home data:', homeData.error);
          }

          if (walletTotals && walletTotals.all_primal_wallets_balance !== undefined) {
            setPrimalAccountBalance(walletTotals.all_primal_wallets_balance);
          }

          if (walletTotals && walletTotals.strike_account_balance !== undefined) {
            setWalletTotalsStrikeAccountBalance(walletTotals.strike_account_balance);
          }

          setLoading(false);
        } catch (error) {
          console.error('Error fetching API data:', error);
          setLoading(false);
        }
      };

      fetchData();
    }, []);

    if (loading) {
      return <div className="loader"><span></span></div>;
    }

    if (!data) {
      return <div>No data available</div>;
    }

    const {
      authenticated,
      authenticated_versions,
      authenticated_users,
      da_unique_nostrjson,
      da_unique_ips,
      wallet,
      subs
    } = data;

    const numberOfActivatedSubscriptions = subs?.number_of_activated_subscriptions || 0;
    const numberOfActivatedSubscriptions24h = subs?.number_of_activated_subscriptions_24h || 0;
    const subAnnualValue = numberOfActivatedSubscriptions * 5.50 * 12; // Example calculation, adjust as needed

    const androidAppData = authenticated_versions['android'];
    const iOSAppData = authenticated_versions['ios'];
    const webAppData = authenticated_versions['web'];

    const balanceCheck = walletTotalsStrikeAccountBalance - primalAccountBalance;

    return (
      <>
     <div className="Container1">
        <div className="GraphContainerTwo">
          <div className="GroupContainerA">
          <div className="ChartTitleDAUA">Active Subs</div>
          <div className="Group588">
          <p>{formatNumberWithCommas(numberOfActivatedSubscriptions)}</p>
          </div>
        </div>
          <div className="chartplaceA">
          <SubsChart/>
        </div>
        </div>
        <div className="GroupContainerOneHome">
            <div className="ContainerOneHome">
              <div className="AuthenticatedHome">
                <div className="GroupContainer">
                <div className="AuthenticatedHomeTitle">New Subs 24h</div>
                <div className="Group408">
                  <p>{formatNumberWithCommas(numberOfActivatedSubscriptions24h)}</p>
                  </div>
                  </div>
                  </div>
              </div>
              <div className="ContainerTwoHome">
              <div className="WebAppDataHome">
                <div className="GroupContainer1">
                  <div className="WebAppHomeTitle2">Sub Annual Value</div>
                    <div className="Group715">
                      <p>${formatNumberWithCommas(subAnnualValue)}</p>
                    </div>
                </div>
              </div>
              </div>
              <div className="GraphContainerThree">
          <div className="GroupContainerA">
          <div className="ChartTitleDAUA"></div>
          <div className="Group588">
          <p></p>
          </div>
          </div>
        </div>
        </div>
              </div>
        <div className="Container1">
        <div className="GraphContainerTwo">
          <div className="GroupContainerA">
          <div className="ChartTitleDAUA">Active Wallets</div>
          <div className="Group588">
          <p>{formatNumberWithCommas(wallet.number_of_activated_wallets || 0)}</p>
          </div>
          </div>
          <div className="chartplaceA">
          <ActivationsChart/>
        </div>
        </div>
        <div className="GroupContainerOneHome">
            <div className="ContainerOneHome">
              <div className="AuthenticatedHome">
                <div className="GroupContainer">
                <div className="AuthenticatedHomeTitle">Activations 24h</div>
                <div className="Group408">
                  <p>{formatNumberWithCommas(wallet.number_of_activated_wallets_24h || 0)}</p>
                  </div>
                  </div>
                  </div>
              <div className="UniqueIPHome">
                <div className="GroupContainer">
                <div className="UniqueIPHomeTitle">Transactions 24h</div>
                <div className="Group410">
                  <p>{formatNumberWithCommas(wallet.number_of_transactions_24h || 0)}</p>
                  </div>
                  </div>
              </div>
              <div className="NostrUniqueHome">
                <div className="GroupContainer">
                <div className="NostrUniqueHomeTitle">Tx Sats 24h</div>
                <div className="Group412">
                  <p>{formatNumberWithCommas(wallet.transaction_volume_24h * 100000000)}</p>
                  </div>
                  </div>
              </div>
              </div>
              <div className="ContainerTwoHome">
              <div className="WebAppDataHome">
                <div className="GroupContainer1">
                  <div className="WebAppHomeTitle1">Total Balance</div>
                    <div className="Group714">
                      <p>{wallet.strike_account_balance || 0}</p>
                      <p className={`balanceCheck ${balanceCheck < 0 ? 'negative' : 'positive'}`}>
                    {formatNumberWithCommas(balanceCheck * 100000000)} </p>
                    </div>
                </div>
              </div>
              <div className="IOSHome">
                <div className="GroupContainer">
                <div className="IOSHomeTitle">Total Transactions</div>
                <div className="Group414">
                  <p>{formatNumberWithCommas(wallet.number_of_transactions || 0)}</p>
                  </div>
                  </div>
              </div>
              <div className="AndroidHome">
                <div className="GroupContainer">
                <div className="AndroidHomeTitle">Total Tx (BTC)</div>
                <div className="Group416">
                  <p>{wallet.transaction_volume || 0}</p>
                  </div>
                  </div>
              </div>
              </div>
              </div>
              </div>
          <div className="Container1">
        <div className="GraphContainerOne">
          <div className="GroupContainer">
          <div className="ChartTitleDAU">Primal DAU</div>
          <div className="Group500">
          <p>{formatNumberWithCommas(authenticated.DAU || 0)}</p>
          </div>
          </div>
          <div className="chartplace">
        <HomeChartData/>
        </div>
        </div>
        <div className="GroupContainerOneHome">
        <div className="ContainerOneHome">
          <div className="AuthenticatedHome">
            <div className="GroupContainer">
            <div className="AuthenticatedHomeTitle">Primal WAU</div>
            <div className="Group408">
              <p>{formatNumberWithCommas(authenticated.WAU || 0)}</p>
              </div>
              </div>
              </div>
          <div className="UniqueIPHome">
            <div className="GroupContainer">
            <div className="UniqueIPHomeTitle">Primal MAU</div>
            <div className="Group410">
              <p>{formatNumberWithCommas(authenticated.MAU || 0)}</p>
              </div>
              </div>
          </div>
          <div className="NostrUniqueHome">
            <div className="GroupContainer">
            <div className="NostrUniqueHomeTitle">Primal ALL</div>
            <div className="Group412">
              <p>{formatNumberWithCommas(authenticated_users || 0)}</p>
              </div>
              </div>
          </div>
          </div>
          <div className="ContainerTwoHome">
          <div className="WebAppDataHome">
            <div className="GroupContainer">
            <div className="WebAppHomeTitle">Web DAU</div>
            <div className="Group414">
              <p>{formatNumberWithCommas(webAppData.DAU || 0)}</p>
              </div>
              <div className="webline"></div>
              </div>
          </div>
          <div className="IOSHome">
            <div className="GroupContainer">
            <div className="IOSHomeTitle">iOS DAU</div>
            <div className="Group414">
              <p>{formatNumberWithCommas(iOSAppData.DAU || 0)}</p>
              </div>
              <div className="iosline"></div>
              </div>
          </div>
          <div className="AndroidHome">
            <div className="GroupContainer">
            <div className="AndroidHomeTitle">Android DAU</div>
            <div className="Group416">
              <p>{formatNumberWithCommas(androidAppData.DAU || 0)}</p>
              </div>
              <div className="androidline"></div>
              </div>
          </div>
          </div>
          </div>
          </div>
      </>
    );
}

const Home = () => {
    return (
        <div className="MainBody">
          <div className="HomeData">
            <HomeData />
          </div>
        </div>
    )
}
 export default Home
