import React, { useState, useEffect, useRef, useCallback } from 'react';
import SearchButton from '../Images/Search.svg';
import BanUserButton from '../Images/BanUserButton.svg';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';
import { useToastContext } from './Toast';
import '../App.css';

const Uploaded_Media = () => {
    const [pubkeyInput, setPubkeyInput] = useState('');
    const { sendWarning, sendSuccess } = useToastContext();
    const [userInfo, setUserInfo] = useState(null);
    const [loading, setLoading] = useState(false);
    const [mediaData, setMediaData] = useState([]);
    const [mediaUntil, setMediaUntil] = useState(Math.floor(Date.now() / 1000));
    const [mediaUntilPubkey, setMediaUntilPubkey] = useState(Math.floor(Date.now() / 1000));
    const [hasMoreGeneral, setHasMoreGeneral] = useState(true);
    const [hasMoreSpecific, setHasMoreSpecific] = useState(true);
    const [contextMenu, setContextMenu] = useState({ visible: false, x: 0, y: 0, selectedMedia: null });
    const observer = useRef();

    const Bech32MaxSize = 5000;

    const handleInputChange = (e) => {
        setPubkeyInput(e.target.value);
    };

    const npubDecode = (nip19) => {
        try {
            const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
            const data = new Uint8Array(bech32.fromWords(words));
            if (prefix !== 'npub') throw new Error('Invalid prefix');
            return bytesToHex(data);
        } catch (error) {
            console.error('Error decoding npub:', error);
            return '';
        }
    };

    const bytesToHex = (bytes) => {
        return Array.from(bytes)
            .map(byte => byte.toString(16).padStart(2, '0'))
            .join('');
    };

    const hexToNpub = (hex) => {
        const bytes = hexToBytes(hex);
        return bech32.encode('npub', bech32.toWords(bytes), Bech32MaxSize);
    };

    const fetchUserInfo = async (npub) => {
        const hexPubkey = npubDecode(npub);
        const payload = JSON.stringify(["user_infos", { "pubkeys": [hexPubkey] }]);
        try {
            const response = await fetch("https://dev.primal.net/api", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: payload,
            });
            if (response.ok) {
                const data = await response.json();
                if (data && Array.isArray(data) && data.length > 0) {
                  const userData = JSON.parse(data[0].content);
                  setUserInfo(userData);
                  return userData;
                }
            } else {
                console.error("Failed to fetch user info:", response.statusText);
            }
        } catch (error) {
            console.error("Error fetching user info:", error);
        }
        return null;
    };

    const sortMedia = (media) => {
        return media.sort((a, b) => b.created_at - a.created_at);
    };

    const groupMediaByDate = (media) => {
        const sortedMedia = media.sort((a, b) => b.created_at - a.created_at);
        const groupedMedia = sortedMedia.reduce((acc, media) => {
            const date = new Date(media.created_at * 1000).toDateString();
            if (!acc[date]) {
                acc[date] = [];
            }
            acc[date].push(media);
            return acc;
        }, {});
        return groupedMedia;
    };

    const lastMediaElementRef = useCallback(node => {
        if (loading) return;
        if (observer.current) observer.current.disconnect();
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                if (pubkeyInput && hasMoreSpecific) {
                    fetchMediaData(true);
                } else if (!pubkeyInput && hasMoreGeneral) {
                    fetchMediaData1(true);
                }
            }
        });
        if (node) observer.current.observe(node);
    }, [loading, hasMoreSpecific, hasMoreGeneral, pubkeyInput]);

    const handleBanUser = async (e) => {
        e.preventDefault();
        const userInfo = await fetchUserInfo(pubkeyInput);
        if (!userInfo) {
            sendWarning('Failed to fetch user details.');
            return;
        }
        const confirmMessage = `Do you want to ban ${userInfo.display_name}, ${userInfo.nip05}?`;
        if (!window.confirm(confirmMessage)) {
            return;
        }
        sendWarning("Please wait...");
        try {
            const hexPubkey = npubDecode(pubkeyInput);
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'purge uploads request',
            });
            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['purge_uploads', {pubkey: hexPubkey}],
                }),
            });
            if (!response.ok) throw new Error('Failed to fetch data from the API');
            const result = await response.json();
            if (result.status) {
                sendSuccess('Uploads successfully purged for the user.');
            } else {
                throw new Error(result.message || 'Failed to purge uploads');
            }
        } catch (error) {
            console.error('Error in purging uploads:', error);
            sendWarning(error.message || 'Failed to purge uploads');
        } finally {
            setLoading(false);
        }
    };

    const fetchMediaData = async (isPaging = false) => {
        setLoading(true);
        if (!isPaging) {
            setMediaData([]);
        }
        const hexPubkey = npubDecode(pubkeyInput);
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'fetch uploaded media for specific pubkey',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['uploaded_media', {pubkey: hexPubkey, until: mediaUntilPubkey, limit: 50}],
                }),
            });

            if (!response.ok) throw new Error('Failed to fetch media data');
            const result = await response.json();
            if (result && result.uploads.length > 0) {
                const sortedMedia = sortMedia(result.uploads);
                setMediaData(prev => isPaging ? [...prev, ...sortedMedia] : sortedMedia);
                setMediaUntilPubkey(result.uploads[result.uploads.length - 1].created_at);
                setHasMoreSpecific(result.uploads.length === 50);
            } else {
                setHasMoreSpecific(false);
            }
        } catch (error) {
            console.error('Error fetching media data:', error);
            sendWarning(error.message || 'Failed to fetch media data');
        } finally {
            setLoading(false);
        }
    };

    const fetchMediaData1 = async (isPaging = false) => {
        setLoading(true);
        if (!isPaging) {
            setMediaData([]);
        }
        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'fetch all uploaded media',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['uploaded_media', {until: mediaUntil, limit: 50}],
                }),
            });

            if (!response.ok) throw new Error('Failed to fetch media data');
            const result = await response.json();
            if (result && result.uploads.length > 0) {
                const sortedMedia = sortMedia(result.uploads);
                setMediaData(prev => isPaging ? [...prev, ...sortedMedia] : sortedMedia);
                setMediaUntil(result.uploads[result.uploads.length - 1].created_at);
                setHasMoreGeneral(result.uploads.length === 50);
            } else {
                setHasMoreGeneral(false);
            }
        } catch (error) {
            console.error('Error fetching media data:', error);
            sendWarning(error.message || 'Failed to fetch media data');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchMediaData1();
    }, []);

    const handleContextMenu = (e, media) => {
        e.preventDefault();  // Prevent default context menu
        const imageRect = e.target.getBoundingClientRect();
        setContextMenu({
            visible: true,
            x: imageRect.left + (imageRect.width / 2),
            y: imageRect.top - 10,  // Place the menu above the image with space for the pointer
            selectedMedia: media,
        });
    };
    
    const handleClick = (e, media) => {
        e.preventDefault();  // Prevent default action
        handleContextMenu(e, media);  // Trigger context menu on left click
    };
    

    const handleCloseContextMenu = () => {
        setContextMenu({ visible: false, x: 0, y: 0, selectedMedia: null });
    };

    const handleProfileClick = () => {
        if (contextMenu.selectedMedia) {
            window.open(`https://primal.net/p/${hexToNpub(contextMenu.selectedMedia.pubkey)}`, '_blank');
        }
        handleCloseContextMenu();
    };

    const handlePurgeImageClick = async () => {
        if (!contextMenu.selectedMedia) return;

        const url = contextMenu.selectedMedia.url;
        const page_url = window.location.href;
        const comment = prompt("Please enter a comment:", "");

        if (!comment) {
            sendWarning('Purging image cancelled. No comment provided.');
            return;
        }

        try {
            const event = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'purge image request',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: event,
                    request: ['purge_image', { url, page_url, comment }],
                }),
            });

            const result = await response.json();
            if (result.status) {
                sendSuccess('Image successfully purged.');
                // Update UI to indicate image is purged
                const updatedMedia = mediaData.map((media) => {
                    if (media.url === url) {
                        return { ...media, purged: true };
                    }
                    return media;
                });
                setMediaData(updatedMedia);
            } else {
                throw new Error(result.logs || 'Failed to purge image');
            }
        } catch (error) {
            console.error('Error purging image:', error);
            sendWarning(error.message || 'Failed to purge image');
        } finally {
            handleCloseContextMenu();
        }
    };

    const renderMediaSections = () => {
        const groupedMedia = groupMediaByDate(mediaData);
        const dates = Object.keys(groupedMedia);
        return dates.map((date, dateIndex) => (
            <div key={date}>
                <h2 className="MediaUploadDate">{date}</h2>
                <div className="MediaDisplay">
                    {groupedMedia[date].map((media, index) => {
                        const isLastElement = dateIndex === dates.length - 1 && index === groupedMedia[date].length - 1;
                        const mediaSrc = media.thumbnail_url ? media.thumbnail_url : media.url; // Check if thumbnail_url exists
                        return (
                            <div
                                ref={isLastElement ? lastMediaElementRef : null}
                                key={index}
                                className="MediaItem"
                                onClick={(e) => handleClick(e, media)}  // Trigger on left click
                                onContextMenu={(e) => handleContextMenu(e, media)}  // Trigger on right click
                            >
                                <a href="#" onClick={(e) => e.preventDefault()}>
                                    <img src={mediaSrc} alt={`Uploaded Media ${index}`} />  {/* Use mediaSrc */}
                                </a>
                            </div>
                        );
                    })}
                </div>
            </div>
        ));
    };
    

    const renderContextMenu = () => {
        if (!contextMenu.visible) return null;
        return (
            <div
                className="contextMenu1"
                style={{ top: `${contextMenu.y}px`, left: `${contextMenu.x}px`, transform: 'translateX(-50%)' }}
            >
                <div className="contextMenuContent">
                    <button onClick={handleProfileClick}>Profile</button>
                    <button onClick={handlePurgeImageClick}>Purge Image</button>
                </div>
                <div className="contextMenuPointer"></div>
            </div>
        );
    };

    return (
        <div className="MainBody" onClick={handleCloseContextMenu}>
            <div className="UMTitle">Uploaded Media</div>
            <form className="BanUserForm" onSubmit={e => e.preventDefault()}>
                <label className="label">
                    Search by User (pubkey)
                    <input
                        type="text"
                        className="SearchBanUser"
                        value={pubkeyInput}
                        onChange={handleInputChange}
                    />
                </label>
                <button type="button" onClick={() => fetchMediaData()} className="SearchBanButton">
                    <img src={SearchButton} alt="Search Button" />
                </button>
                <button type="button" onClick={handleBanUser} className="BanSearchButton">
                    <img src={BanUserButton} alt="Ban User Button" />
                </button>
            </form>
            {loading && <div className="loader"><span></span></div>}
            <div>
                {renderMediaSections()}
            </div>
            {!hasMoreGeneral && !pubkeyInput && <div>No more media available.</div>}
            {!hasMoreSpecific && pubkeyInput && <div>No more specific media available.</div>}
            {renderContextMenu()}
        </div>
    );
};

export default Uploaded_Media;
