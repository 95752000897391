import React, { useState, useLayoutEffect } from 'react';
import '../App.css';

export const useSystemStatus = () => {
    const [systemData, setSystemData] = useState(null);
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['system_monitor', {}],
                }),
            });

            const data = await response.json();
            if (data && !data.error) {
                setSystemData(data);
            } else {
                console.error('Error fetching system data:', data.error);
            }
            setLoading(false);
        } catch (error) {
            console.error('Error fetching system data:', error);
            setLoading(false);
        }
    };

    useLayoutEffect(() => {
        // Fetch data as soon as the component mounts
        fetchData();

        // Set up an interval to fetch data every 30 seconds
        const intervalId = setInterval(fetchData, 5000);

        // Clean up the interval when the component unmounts
        return () => clearInterval(intervalId);
    }, []);

    return { systemData, loading, fetchData };
};



const SystemStatus = () => {
    const [refreshing, setRefreshing] = useState(false); // Add refreshing state
    const { systemData, loading, fetchData } = useSystemStatus(); // Ensure fetchData is available

    const handleRefresh = async () => {
        setRefreshing(true);
        await fetchData(); // Use the fetchData from the hook
        setRefreshing(false);
    };

    if (loading) {
        return <div className="loader"><span></span></div>;
    }

    if (!systemData || !systemData.components) {
        return <div>No data available</div>;
    }

    return (
        <div className="MainBody">
            <div className="UMTitle">System Status</div>
            <button className="RefreshButton" onClick={handleRefresh} disabled={refreshing}>
            </button>
            <table className="SystemTable">
                <tbody>
                    {systemData.components.map((component, index) => (
                        <tr key={index}>
                            <td>
                                <span className={`StatusCircle ${component.status ? 'OK' : 'NotOK'}`} />
                            </td>
                            <td><div className="SystemName">{component.name}</div></td>
                            <td><div className="SystemDescription">{component.status_description}</div></td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

export default SystemStatus;
