import React, { useState, useEffect } from 'react';
import '../App.css';
import Vector from '../Images/Vector 150.svg';
import Delete from '../Images/delete-icon.svg'
import { useToastContext } from './Toast';
import { useHistory, useLocation } from 'react-router-dom';

const UserModeration = () => {
  const [isContextMenuVisible, setContextMenuVisible] = useState(false);
  const [spamAllowData, setSpamAllowData] = useState([]);
  const [spamBlockData, setSpamBlockData] = useState([]);
  const [nsfwAllowData, setNsfwAllowData] = useState([]);
  const [nsfwBlockData, setNsfwBlockData] = useState([]);

  const [spamAllowNpub, setSpamAllowNpub] = useState('');
  const [spamBlockNpub, setSpamBlockNpub] = useState('');
  const [nsfwAllowNpub, setNsfwAllowNpub] = useState('');
  const [nsfwBlockNpub, setNsfwBlockNpub] = useState('');
  const { sendWarning, sendSuccess } = useToastContext();
  
  const history = useHistory();
  const location = useLocation();

  const category = new URLSearchParams(location.search).get('category');

  const [selectedPage, setSelectedPage] = useState(category ||'Spam Accounts');

  const formatDate = (timestamp) => {
    const date = new Date(timestamp * 1000);
    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', second: '2-digit' };
    return date.toLocaleDateString(undefined, options);
  };

  const toggleContextMenu = () => {
    setContextMenuVisible(!isContextMenuVisible);
  };

  const handlePageSelect = (page) => {
    setSelectedPage(page);
    setContextMenuVisible(false);
    history.push(`/user_moderation?category=${page}`);
  };

  const deleteUser = async (listType, index) => {
    const userToDelete =
      listType === 'spam_allow'
        ? spamAllowData[index]
        : listType === 'spam_block'
        ? spamBlockData[index]
        : listType === 'nsfw_allow'
        ? nsfwAllowData[index]
        : nsfwBlockData[index];

    if (!userToDelete) return;

    const confirmed = window.confirm(`Are you sure you want to delete the user '${userToDelete.handle}'?`);

    if (confirmed) {
      try {
        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want out',
        });

        const response = await fetch('https://admin.primal.net/api2', {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            event_from_user: e,
            request: ['delete_from_list', { list: listType, npub: userToDelete.npub }],
          }),
        });

        if (response.ok) {
          if (listType === 'spam_allow') {
            setSpamAllowData((prevData) => prevData.filter((item, i) => i !== index));
          } else if (listType === 'spam_block') {
            setSpamBlockData((prevData) => prevData.filter((item, i) => i !== index));
          } else if (listType === 'nsfw_allow') {
            setNsfwAllowData((prevData) => prevData.filter((item, i) => i !== index));
          } else if (listType === 'nsfw_block') {
            setNsfwBlockData((prevData) => prevData.filter((item, i) => i !== index));
          }
          sendSuccess('User deleted successfully');
        } else {
          console.error(`Failed to delete user from ${listType}`);
          sendWarning('Failed to delete user');
        }
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  const handleAddUser = async (listType) => {
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });

      const npubToAdd =
        listType === 'spam_allow'
          ? spamAllowNpub
          : listType === 'spam_block'
          ? spamBlockNpub
          : listType === 'nsfw_allow'
          ? nsfwAllowNpub
          : nsfwBlockNpub;

      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['add_to_list', { list: listType, npub: npubToAdd }],
        }),
      });

      if (response.ok) {
        console.log(`User added to ${listType}`);
        sendSuccess('User added successfully');
      } else {
        console.error(`Failed to add user to ${listType}`);
        sendWarning(`Failed to add user to ${listType}`);
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        await new Promise((resolve) => setTimeout(resolve, 500));

        const e = await window.nostr.signEvent({
          created_at: Math.floor(new Date().getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: 'i want in',
        });

        const listParameters = {
          'Spam Accounts': ['spam_allow', 'spam_block'],
          'NSFW Accounts': ['nsfw_allow', 'nsfw_block'],
        };

        const selectedLists = listParameters[selectedPage];

        selectedLists.forEach(async (list) => {
          const response = await fetch('https://admin.primal.net/api2', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
              event_from_user: e,
              request: ['get_list', { list }],
            }),
          });

          if (response.ok) {
            const data = await response.json();

            if (list === 'spam_allow') {
              setSpamAllowData(data.list);
            } else if (list === 'spam_block') {
              setSpamBlockData(data.list);
            } else if (list === 'nsfw_allow') {
              setNsfwAllowData(data.list);
            } else if (list === 'nsfw_block') {
              setNsfwBlockData(data.list);
            }
          } else {
            console.error(`API request failed for ${list}`);
          }
        });
      } catch (error) {
        console.error('An error occurred:', error);
      }
    };

    fetchData();
  }, [selectedPage]);

  return (
    <div className="MainBody">
      <div className="UMTitle" onClick={toggleContextMenu}>
        {selectedPage}
        <img className="UMArrow" src={Vector} alt="" />
      </div>
      {isContextMenuVisible && (
        <div className="ContextMenu">
          <div
            className={`ContextMenuItem ${selectedPage === 'Spam Accounts' ? 'selected' : ''}`}
            onClick={() => handlePageSelect('Spam Accounts')}
          >
            Spam Accounts {selectedPage === 'Spam Accounts' && <span>✔</span>}
          </div>
          <div
            className={`ContextMenuItem ${selectedPage === 'NSFW Accounts' ? 'selected' : ''}`}
            onClick={() => handlePageSelect('NSFW Accounts')}
          >
            NSFW Accounts {selectedPage === 'NSFW Accounts' && <span>✔</span>}
          </div>
        </div>
      )}
      {selectedPage === 'Spam Accounts' && (
        <div>
          <h3 className="AllowListTitle">Allow List</h3>
          <div className="AddUserFormUM">
            <div>
              <label  className="label" htmlFor="AddUserNpubUM">Paste user npub to add them to the Spam Accounts allow list:</label>
              <input
                type="text"
                id="AddUserNpubUM"
                placeholder="npub"
                value={spamAllowNpub}
                onChange={(e) => setSpamAllowNpub(e.target.value)}
                className="AddUserNpubUM"
              />
            </div>
            <button className="SubmitAddUserUM" onClick={() => handleAddUser('spam_allow')}></button>
          </div>
          {spamAllowData.length > 0 ? (
            <div>
              <table className="AllowListTable">
                <thead>
                  <tr className="table_header">
                    <th>Handle</th>
                    <th>Npub</th>
                    <th>Delete</th>
                  </tr>
                </thead>
                <tbody>
                  {spamAllowData.map((item, index) => (
                    <tr className="table_row" key={index}>
                      <td>{item.handle}</td>
                      <td>{item.npub}</td>
                      <td>
                        <button  className="Delete" onClick={() => deleteUser('spam_allow', index)}>
                        <img src={Delete} alt="" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ) : (
            <p>No data available for Spam Allow.</p>
          )}

          <h3 className="AllowListTitle">Spam Accounts</h3>
          <div className="AddUserFormUM">
            <div>
              <label  className="label" htmlFor="AddUserNpubUM">Paste user npub to add them to the Spam Accounts allow list:</label>
              <input
                type="text"
                id="AddUserNpubUM"
                placeholder="npub"
                value={spamBlockNpub}
                onChange={(e) => setSpamBlockNpub(e.target.value)}
                className="AddUserNpubUM"
              />
            </div>
            <button className="SubmitAddUserUM" onClick={() => handleAddUser('spam_block')}></button>
          </div>
          {spamBlockData.length > 0 ? (
            <table className="AllowListTable">
              <thead>
                <tr className="table_header">
                  <th>Handle</th>
                  <th>Npub</th>
                  <th>Added On</th>
                </tr>
              </thead>
              <tbody>
                {spamBlockData.map((item, index) => (
                  <tr className="table_row" key={index}>
                    <td>{item.handle}</td>
                    <td>{item.npub}</td>
                    <td>{formatDate(item.added_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available for Spam Accounts.</p>
          )}
        </div>
      )}
      {selectedPage === 'NSFW Accounts' && (
        <div>
          <h3 className="AllowListTitle">Allow List</h3>
          <div className="AddUserFormUM">
            <div>
              <label className="label" htmlFor="AddUserNpubUM">Paste user npub to add them to the Spam Accounts allow list:</label>
              <input
                type="text"
                id="AddUserNpubUM"
                placeholder="npub"
                value={nsfwAllowNpub}
                onChange={(e) => setNsfwAllowNpub(e.target.value)}
                className="AddUserNpubUM"
              />
            </div>
            <button className="SubmitAddUserUM" onClick={() => handleAddUser('nsfw_allow')}></button>
          </div>
          {nsfwAllowData.length > 0 ? (
            <table className="AllowListTable">
              <thead>
                <tr className="table_header">
                  <th>Handle</th>
                  <th>Npub</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {nsfwAllowData.map((item, index) => (
                  <tr className="table_row" key={index}>
                    <td>{item.handle}</td>
                    <td>{item.npub}</td>
                    <td>
                    <button  className="Delete" onClick={() => deleteUser('spam_allow', index)}>
                        <img src={Delete} alt="" />
                        </button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available for NSFW Allow List.</p>
          )}

          <h3 className="AllowListTitle">NSFW Accounts</h3>
          <div className="AddUserFormUM">
            <div>
              <label className="label" htmlFor="AddUserNpubUM">Paste user npub to add them to the Spam Accounts allow list:</label>
              <input
                type="text"
                id="AddUserNpubUM"
                placeholder="npub"
                value={nsfwBlockNpub}
                onChange={(e) => setNsfwBlockNpub(e.target.value)}
                className="AddUserNpubUM"
              />
            </div>
            <button className="SubmitAddUserUM" onClick={() => handleAddUser('nsfw_block')}></button>
          </div>
          {nsfwBlockData.length > 0 ? (
            <table className="AllowListTable">
              <thead>
                <tr className="table_header">
                  <th>Handle</th>
                  <th>Npub</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {nsfwBlockData.map((item, index) => (
                  <tr className="table_row" key={index}>
                    <td>{item.handle}</td>
                    <td>{item.npub}</td>
                    <td>{formatDate(item.added_at)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <p>No data available for NSFW Block.</p>
          )}
        </div>
      )}
    </div>
  );
};

export default UserModeration;

