import React, { useEffect, useState } from 'react';
import Delete from '../Images/delete-icon.svg';
import '../App.css';
import { useToastContext } from './Toast';
import { hexToBytes } from '@noble/hashes/utils';
import { bech32 } from '@scure/base';

const Bech32MaxSize = 5000;

const npubEncode = (hex) => {
    return encodeBytes('npub', hexToBytes(hex));
};

const npubDecode = (nip19) => {
    try {
        const { prefix, words } = bech32.decode(nip19, Bech32MaxSize);
        const data = new Uint8Array(bech32.fromWords(words));
        if (prefix !== 'npub') throw new Error('Invalid prefix');
        return bytesToHex(data);
    } catch (error) {
        throw new Error('Error decoding npub: ' + error.message);
    }
};

function bytesToHex(bytes) {
    return Array.from(bytes)
        .map(byte => byte.toString(16).padStart(2, '0'))
        .join('');
}

function encodeBytes(prefix, bytes) {
    const words = bech32.toWords(bytes);
    return bech32.encode(prefix, words, Bech32MaxSize);
}

const Admin = () => {
    const [users, setUsers] = useState([]);
    const [roles, setRoles] = useState([]);
    const [selectedUser, setSelectedUser] = useState('');
    const [selectedRole, setSelectedRole] = useState('');
    const [userRolesData, setUserRolesData] = useState([]);
    const [newUserNpub, setNewUserNpub] = useState('');
    const [userInfos, setUserInfos] = useState({});
    const { sendSuccess, sendWarning } = useToastContext();

    useEffect(() => {
        fetchUserRoles();
        fetchUsers();
        fetchRoles();
    }, []);

    useEffect(() => {
        const fetchUserInfos = async () => {
            const pubkeys = userRolesData.map(userRole => userRole.pubkey);
            const userInfoMap = { ...userInfos };
            let needsUpdate = false;
            for (const pubkey of pubkeys) {
                let userData = JSON.parse(localStorage.getItem(pubkey));
                if (!userData) {
                    needsUpdate = true;
                    await new Promise((resolve) => setTimeout(resolve, 500));
                    const payload = JSON.stringify(["user_infos", { "pubkeys": [pubkey] }]);
                    try {
                        const response = await fetch("https://dev.primal.net/api", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: payload,
                        });
                        if (response.ok) {
                            const data = await response.json();
                            if (data && Array.isArray(data) && data.length > 0) {
                                userData = JSON.parse(data[0].content);
                                localStorage.setItem(pubkey, JSON.stringify(userData));
                            }
                        } else {
                            console.error("Failed to fetch user info:", response.statusText);
                        }
                    } catch (error) {
                        console.error("Error fetching user info:", error);
                    }
                }
                if (userData) {
                    userInfoMap[pubkey] = {
                        display_name: userData.display_name,
                        picture: userData.picture
                    };
                }
            }
            if (needsUpdate) {
                setUserInfos(userInfoMap);
                localStorage.setItem('userInfos', JSON.stringify(userInfoMap));
            }
        };
        if (userRolesData.length > 0) {
            fetchUserInfos();
        }
    }, [userInfos, userRolesData]);

    const fetch_admin = async (role, func, args) => {
        try {
            const e = await window.nostr.signEvent({
                created_at: Math.floor(new Date().getTime() / 1000),
                kind: 10000222,
                tags: [],
                content: 'i want in',
            });

            const response = await fetch('https://admin.primal.net/api2', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    event_from_user: e,
                    request: ['admin', { role, func, args }],
                }),
            });

            if (!response.ok) {
                throw new Error('Failed to fetch data');
            }

            return await response.json();
        } catch (error) {
            console.error('Error fetching data:', error);
            return { error: error.message };
        }
    };

    const fetchUserRoles = async () => {
        const response = await fetch_admin('admin', 'user_roles', {});
        if (!response.error) {
            setUserRolesData(response);
        }
    };

    const fetchUsers = async () => {
        const response = await fetch_admin('admin', 'users', {});
        if (!response.error) {
            setUsers(response);
        }
    };

    const fetchRoles = async () => {
        const response = await fetch_admin('admin', 'roles', {});
        if (!response.error) {
            setRoles(response);
        }
    };

    const handleUserChange = (event) => {
        setSelectedUser(event.target.value);
    };

    const handleRoleChange = (event) => {
        setSelectedRole(event.target.value);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const args = {
            user_pubkey: selectedUser,
            role_name: selectedRole,
        };
        const response = await fetch_admin('admin', 'add_user_to_role', args);
        if (!response.error) {
            fetchUserRoles();
            sendSuccess('User added to role successfully!');
        } else {
            console.error('Error adding user to role:', response.error);
            sendWarning('Failed to add user to role.');
        }
    };

    const handleDeleteUserRole = async (userRoleId) => {
        const confirmed = window.confirm(`Are you sure you want to delete User Role ${userRoleId}?`);
        if (confirmed) {
            const response = await fetch_admin('admin', 'delete_user_role', { user_role_id: userRoleId });
            if (!response.error) {
                fetchUserRoles();
                sendSuccess('User role deleted successfully!');
            } else {
                console.error('Error deleting UserRole entry:', response.error);
                sendWarning('Failed to delete user role.');
            }
        }
    };

    const handleDeleteUser = async (userId) => {
        const confirmed = window.confirm(`Are you sure you want to delete User ${userId}?`);
        if (confirmed) {
            const response = await fetch_admin('admin', 'delete_user', { user_id: userId });
            if (!response.error) {
                fetchUsers();
                sendSuccess('User deleted successfully!');
            } else {
                console.error('Error deleting user:', response.error);
                sendWarning('Failed to delete user.');
            }
        }
    };

    const handleNewUserInputChange = (event) => {
        setNewUserNpub(event.target.value);
    };

    const handleAddUserSubmit = async (event) => {
        event.preventDefault();
        try {
            const hexNpub = npubDecode(newUserNpub);
            const response = await fetch_admin('admin', 'add_user', { pubkey: hexNpub });
            if (!response.error) {
                fetchUsers();
                sendSuccess('User added successfully!');
                setNewUserNpub('');
            } else {
                console.error('Error adding user:', response.error);
                sendWarning('Failed to add user.');
            }
        } catch (error) {
            console.error('Error decoding npub or adding user:', error);
            sendWarning('Failed to add user. Invalid npub format.');
        }
    };

    return (
        <div className="MainBody">
            <div className="UMTitle">Admin</div>
                <form className="dropdown-container" onSubmit={handleSubmit}>
                    <div className="dropdown">
                    <select
                        id="usersDropdown"
                        className="dropdown"
                        value={selectedUser}
                        onChange={handleUserChange}
                    >
                        <option value="" disabled>Select a user</option>
                        {users.map(user => (
                            <option key={user.pubkey} value={user.pubkey}>
                                {userInfos[user.pubkey]?.display_name || `npub1${npubEncode(user.pubkey)}`}
                            </option>
                        ))}
                    </select>
                    </div>

                    <div className="dropdown">
                            <select
                                id="rolesDropdown"
                                className="dropdown"
                                value={selectedRole}
                                onChange={handleRoleChange}
                            >
                                <option value="" disabled>Role</option>
                                {roles.map(role => (
                                <option key={role.name} value={role.name}>
                                    {role.name}
                                </option>
                                ))}
                            </select>
                    </div>
                    <button className="addUserToRoleButton" type="submit"></button>
                </form>
            <table className="UserRole_table">
            <thead>
                <tr className="table_header">
                    <th></th>
                    <th>User</th>
                    <th>Npub</th>
                    <th>Role</th>
                    <th>Delete</th>
                </tr>
            </thead>
            <tbody>
            {userRolesData.map((userRole, index) => (
            <tr className="table_row" key={index}>
            <td>
                <div className="ProfilePicture1" style={{
                    backgroundImage: `url(${userInfos[userRole.pubkey]?.picture})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                }}></div>
            </td>
            <td>{userInfos[userRole.pubkey]?.display_name}</td>
            <td>{npubEncode(userRole.pubkey)}</td>
            <td>{userRole.role_name}</td>
            <td>
                <button className="Delete" onClick={() => handleDeleteUserRole(userRole.user_role_id)}>
                    <img src={Delete} alt="" />
                </button>
            </td>
        </tr>
        ))}
            </tbody>
        </table>
        <div className="AddUserFormAdmin">
                <form className="AddUserFormAdmin" onSubmit={handleAddUserSubmit}>
                    <div>
                        <label className="label" htmlFor="AddUserNpubAdmin">Paste user npub to add them to the Admin Accounts allow list:</label>
                        <input
                            type="text"
                            id="AddUserNpubUM"
                            placeholder="npub"
                            className="AddUserNpubAdmin"
                            value={newUserNpub}
                            onChange={handleNewUserInputChange}
                        />
                    </div>
                    <button type="submit" className="SubmitAddUserAdmin"></button>
                </form>
            </div>
            <table className="User_table">
                <thead>
                    <tr className="table_header">
                        <th></th>
                        <th>User</th>
                        <th>Npub</th>
                        <th>Delete</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, index) => (
                        <tr className="table_row" key={index}>
                        <td>
                            <div className="ProfilePicture1" style={{
                                backgroundImage: `url(${userInfos[user.pubkey]?.picture})`,
                                backgroundSize: "cover",
                                backgroundPosition: "center",
                            }}></div></td>
                        <td>{userInfos[user.pubkey]?.name}</td>
                        <td>{npubEncode(user.pubkey)}</td>
                        <td>
                            <button className="Delete" onClick={() => handleDeleteUser(user.user_id)}>
                                <img src={Delete} alt="" />
                            </button>
                        </td>
                    </tr>
                    ))}
                </tbody>
            </table>
            </div>
    );
};

export default Admin;
