import React, { useEffect, useRef, useState } from 'react';
import Chart from 'chart.js/auto';

const timestampToDateString = (timestamp) => {
    const tsInMilliseconds = timestamp * 1000;
    const date = new Date(tsInMilliseconds);
    return date.toLocaleDateString();
}

const ActivationsChart = () => {
    const chartRef = useRef(null);
    const [activationData, setActivationData] = useState({
        labels: [],
        values: [],
    });

    const [isFetchingDone, setIsFetchingDone] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsFetchingDone(false);
                const e = await window.nostr.signEvent({
                    created_at: Math.floor(new Date().getTime() / 1000),
                    kind: 10000222,
                    tags: [],
                    content: 'i want in',
                });

                const response = await fetch('https://admin.primal.net/api2', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify({
                        event_from_user: e,
                        request: ['home', {}],
                    }),
                });

                if (response.ok) {
                    const data = await response.json();
                    setActivationData({
                        labels: data.wallet.activations_graph.labels,
                        values: data.wallet.activations_graph.values,
                    });
                } else {
                    console.error('Failed to fetch data from the API');
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsFetchingDone(true);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (isFetchingDone && chartRef.current) {
            const ctx = chartRef.current.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: activationData.labels.map(timestampToDateString),
                    datasets: [{
                        label: 'Activations',
                        data: activationData.values,
                        backgroundColor: '#B71874'
                    }],
                },
                options: {
                    scales: {
                      x: {
                        display: false,
                        stacked: true,
                      },
                      y: {
                        display: false,
                        stacked: true,
                      },
                    },
                    plugins: {
                      legend: {
                        display: false,
                      },
                      tooltip: {
                        enabled: true,
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                          label: function(context) {
                            let label = context.dataset.label || '';
                            if (label) {
                              label += ': ';
                            }
                            if (context.parsed.y !== null) {
                              label += context.parsed.y;
                            }
                            return label;
                          }
                        }
                      }
                    },
                    maintainAspectRatio: false,
                },
            });

            return () => chart.destroy();
        }
    }, [activationData, isFetchingDone]);

    return (
        <div>
            <canvas ref={chartRef} style={{height: '150px', width: '100%'}}/>
        </div>
    );
};

export default ActivationsChart;
