import React, { useState, useEffect, useRef } from 'react';
import '../App.css';
import ActiveBarChart from './ActiveGraph';

function DisplayData() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [loadingMore, setLoadingMore] = useState(false);
  const [selectedPlatform, setSelectedPlatform] = useState(null);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [offset, setOffset] = useState(0);
  const limit = 50;
  const observer = useRef();
  const hasMore = useRef(true);

  useEffect(() => {
    fetchInitialData();
  }, [selectedPlatform]);

  const fetchInitialData = async () => {
    setLoading(true);
    await fetchData(0);
    setLoading(false);
  };
  const fetchData = async (fetchOffset) => {
    try {
      const e = await window.nostr.signEvent({
        created_at: Math.floor(new Date().getTime() / 1000),
        kind: 10000222,
        tags: [],
        content: 'i want in',
      });
      const response = await fetch('https://admin.primal.net/api2', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          event_from_user: e,
          request: ['users', { "offset": fetchOffset, "limit": limit }],
        }),
      });
      const newData = await response.json();
      if (newData && !newData.error) {
        setData(prevData => fetchOffset === 0 ? newData : {
          ...prevData,
          authenticated_users_table: [
            ...prevData.authenticated_users_table,
            ...newData.authenticated_users_table,
          ]
        });
        setFilteredUsers(prevUsers => [
          ...prevUsers,
          ...newData.authenticated_users_table,
        ]);
        if (newData.authenticated_users_table.length < limit) {
          hasMore.current = false;
        }
      } else {
        hasMore.current = false;
      }
    } catch (error) {
      hasMore.current = false;
    } finally {
      setLoadingMore(false);
    }
  };

  useEffect(() => {
    const intersectionObserver = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting && hasMore.current && !loading && !loadingMore) {
        setLoadingMore(true);
        setOffset(prevOffset => {
          const newOffset = prevOffset + limit;
          fetchData(newOffset);
          return newOffset;
        });
      }
    }, { rootMargin: '100px' });

    if (observer.current) {
      intersectionObserver.observe(observer.current);
    }

    return () => intersectionObserver.disconnect();
  }, [loading, loadingMore]);

  useEffect(() => {
    if (data && data.authenticated_users_table) {
      let filtered;
      if (selectedPlatform) {
        filtered = data.authenticated_users_table.filter(user => user[3] === selectedPlatform);
      } else {
        filtered = [...data.authenticated_users_table];
      }
      const sortedFilteredUsers = filtered.sort((a, b) => b[2] - a[2]);
      setFilteredUsers(sortedFilteredUsers);
    }
  }, [selectedPlatform, data]);

  const handlePlatformClick = (platform) => {
    setSelectedPlatform(platform);
  };

  const handleAuthenticatedClick = () => {
    setSelectedPlatform(null);
  };

  if (loading && offset === 0) {
    return <div className="loader"></div>;
  }

  if (!data) {
    return <div>No data available</div>;
  }

  const { authenticated, authenticated_versions, nostrjson_unique_ips, app_unique_ips } = data;

  const androidAppData = authenticated_versions['Primal-Android App'];
  const iOSAppData = authenticated_versions['Primal-iOS App'];
  const webAppData = authenticated_versions['Primal-Web App'];


  return (
    <>
      <div className="Container1">
        <div
          className={`Authenticated ${selectedPlatform === null ? 'active' : ''}`}
          onClick={handleAuthenticatedClick}
        >
          <div className="AuthenticatedTitle">Primal</div>
          <div className="Group307">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group308">
            <p>{authenticated.DAU.toLocaleString()}</p>
            <p>{authenticated.WAU.toLocaleString()}</p>
            <p>{authenticated.MAU.toLocaleString()}</p>
          </div>
        </div>
      <div className="UniqueIP">
          <div className="UniqueIPTitle">Nostr</div>
          <div className="Group311">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group312">
            <p>{nostrjson_unique_ips.DAU.toLocaleString()}</p>
            <p>{nostrjson_unique_ips.WAU.toLocaleString()}</p>
            <p>{nostrjson_unique_ips.MAU.toLocaleString()}</p>
          </div>
        </div>
        <div className="NostrUnique">
          <div className="NostrUniqueTitle">Primal Web Visitors</div>
          <div className="Group309">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group310">
            <p>{app_unique_ips.DAU.toLocaleString()}</p>
            <p>{app_unique_ips.WAU.toLocaleString()}</p>
            <p>{app_unique_ips.MAU.toLocaleString()}</p>
          </div>
        </div>
      </div>
      <div className="Container2">
      <div
          className={`WebAppData ${selectedPlatform === 'web' ? 'active' : ''}`}
          onClick={() => handlePlatformClick('web')}
        >
          <div className="WebAppTitle">Web</div>
          <div className="Group313">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group314">
            <p>{webAppData.DAU.toLocaleString()}</p>
            <p>{webAppData.WAU.toLocaleString()}</p>
            <p>{webAppData.MAU.toLocaleString()}</p>
          </div>
        </div>
        <div
          className={`IOS ${selectedPlatform === 'ios' ? 'active' : ''}`}
          onClick={() => handlePlatformClick('ios')}
        >
          <div className="IOSTitle">iOS</div>
          <div className="Group315">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group316">
            <p>{iOSAppData.DAU.toLocaleString()}</p>
            <p>{iOSAppData.WAU.toLocaleString()}</p>
            <p>{iOSAppData.MAU.toLocaleString()}</p>
          </div>
        </div>
        <div
          className={`Android ${selectedPlatform === 'android' ? 'active' : ''}`}
          onClick={() => handlePlatformClick('android')}
        >
          <div className="AndroidTitle">Android</div>
          <div className="Group317">
            <p>DAU:</p>
            <p>WAU:</p>
            <p>MAU:</p>
          </div>
          <div className="Group318">
            <p>{androidAppData.DAU.toLocaleString()}</p>
            <p>{androidAppData.WAU.toLocaleString()}</p>
            <p>{androidAppData.MAU.toLocaleString()}</p>
          </div>
        </div>
      </div>
      <div className="ActiveUsersGraph">
      <ActiveBarChart/>
      </div>
      <div className="authenticated_table_container">
        <table className="authenticated_table">
          <tbody>
            <tr className="table_header">
              <th>User</th>
              <th>Last Login</th>
              <th>App</th>
              <th>Followers</th>
            </tr>
            {filteredUsers.map((user, index) => (
              <tr key={index} className="table_row">
                <td>
                  <a href={user[0][1]} target="_blank" rel="noopener noreferrer">
                    {user[0][0]}
                  </a>
                </td>
                <td>{user[1]}</td>
                <td>{user[3]}</td>
                <td>{user[2].toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
        <div ref={observer} style={{ height: '20px', textAlign: 'center' }}>
          {loading && <div className="loader"><span></span></div>}
        </div>
      </div>
    </>
  );
}

export default DisplayData;