import React from 'react'
import '../App.css'
import { useEffect } from 'react';
import DisplayData from './Authenticated';


const ActiveUsers = () => {

    useEffect( () => {setTimeout(async () => {
        const e = await window.nostr.signEvent({
          created_at: Math.floor((new Date()).getTime() / 1000),
          kind: 10000222,
          tags: [],
          content: "i want in"}
        );
        const response = await fetch("https://admin.primal.net/api2", {
          method: "POST",
          headers: {"Content-Type": "application/json"},
          body: JSON.stringify({event_from_user: e, request: ["users", {}]}),
        });
        const res = await response.json();
        console.log(res);
      }, 500);})
    return (
    <><div className="MainBody">
        <div className="DisplayData">
            <DisplayData />
        </div>
    </div>
    </>
    )
}
 export default ActiveUsers