import React, { useState, useEffect } from "react";
import '../App.css';

function ProfileComponent({ setPubkey }) {
  const [userInfo, setUserInfo] = useState(null);

  useEffect(() => {
    async function fetchUserInfo() {
      await new Promise((resolve) => setTimeout(resolve, 500));
      const pubKey = await window.nostr.getPublicKey();

      const payload = JSON.stringify(["user_infos", { "pubkeys": [pubKey] }]);

      try {
        const response = await fetch("https://dev.primal.net/api", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: payload,
        });

        if (response.ok) {
          const data = await response.json();
          if (data && Array.isArray(data) && data.length > 0) {
            const userData = JSON.parse(data[0].content);
            setUserInfo(userData);
            setPubkey(pubKey);
          }
        } else {
          console.error("Failed to fetch user info:", response.statusText);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    }

    setTimeout(fetchUserInfo, 200);
  }, [setPubkey]);

  return (
    <a href="primal.net" className="ProfileButton">
      <div className="ProfileInfo">
        <div className="UserName">{userInfo?.name}</div>
        <div className="UserVerification">{userInfo?.nip05}</div>
      </div>
      <div className="ProfilePicture" style={{
        backgroundImage: `url(${userInfo?.picture})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
    }}></div>
    </a>
  );
}

export default ProfileComponent;
