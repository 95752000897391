import React from 'react'
import '../App.css'


const PromoCodes = () => {
    return (
    <><div className="MainBody">
    </div>
    </>
    )
}
 export default PromoCodes