import React, { createContext, useContext, useState, useEffect } from 'react';
import '../App.css';

const ToastContext = createContext();

export const useToastContext = () => {
  return useContext(ToastContext);
};

const Toaster = ({ children }) => {
  const [toasts, setToasts] = useState([]);

  useEffect(() => {
    const toastTimeout = setTimeout(() => {
      if (toasts.length > 0) {
        setToasts((prevToasts) => prevToasts.slice(1));
      }
    }, 4000);

    return () => {
      clearTimeout(toastTimeout);
    };
  }, [toasts]);

  const addToast = (message, type) => {
    setToasts((prevToasts) => [...prevToasts, { message, type }]);
  };

  const toastData = {
    sendWarning: (message) => {
      addToast(message, 'warning');
    },
    sendSuccess: (message) => {
      addToast(message, 'success');
    },
    sendInfo: (message) => {
      addToast(message, 'info');
    },
    notImplemented: () => {
      addToast('Feature not available', 'info');
    },
  };

  return (
    <ToastContext.Provider value={toastData}>
      {children}
      <div className="toastHolder">
        {toasts.map((toast, index) => (
          <div key={index}
          className={`toastMessage ${toast.type === 'warning' ? 'toastWarning' : toast.type === 'success' ? 'toastSuccess' : 'toastInfo'}`}>
            {toast.message}
          </div>
        ))}
      </div>
    </ToastContext.Provider>
  );
};

export default Toaster;